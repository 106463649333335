import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {noop} from 'lodash';

import {Switch as StyledSwitch} from './styled';

const Switch = ({checked, onClick = noop, ...props}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => setIsChecked(checked), [checked]);

  const onSwitch = (value) => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onClick(value, newChecked);
  };

  return <StyledSwitch checked={isChecked} onClick={onSwitch} {...props} />;
};

Switch.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};
export default Switch;
