/* eslint-disable */
import { execute} from "./analytics";

export function initDelighted() {
  if(process.env.REACT_APP_ENV !== 'production') {
    window.delighted = {
      survey: () => {}
    };
    return;
  }

  !(function (e, t, r, n) {
    if (!e[n]) {
      for (
        var a = (e[n] = []),
          i = [
            'survey',
            'reset',
            'config',
            'init',
            'set',
            'get',
            'event',
            'identify',
            'track',
            'page',
            'screen',
            'group',
            'alias',
          ],
          s = 0;
        s < i.length;
        s++
      ) {
        var c = i[s];
        a[c] =
          a[c] ||
          (function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              a.push([e, t]);
            };
          })(c);
      }
      a.SNIPPET_VERSION = '1.0.1';
      var o = t.createElement('script');
      (o.type = 'text/javascript'),
        (o.async = !0),
        (o.src =
          'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' +
          r +
          '/' +
          n +
          '.js');
      var p = t.getElementsByTagName('script')[0];
      p.parentNode.insertBefore(o, p);
    }
  })(window, document, 's47jLzX1PXrEtFfk', 'delighted');
}

export const showSurvey = ({id, email, firstName, lastName, stars}) => {
  execute(() => {
    if (window.delighted) {
      delighted.survey({
        email: email,
        name: `${firstName} ${lastName}`,
        properties: {
          id: id,
          stars: stars,
        },
      });
    }
  });
};

export function toggleSurvey(toggle) {
  if (window.delighted) {
    const elements = document.getElementsByClassName('delighted-web-survey');

    if(!elements.length) return;

    if (toggle === 'show') {
      elements[0].style.setProperty("display", "block", "important");
    } else {
      elements[0].style.display = 'none';
    }
  }
}