import {useEffect} from 'react';

import {toggleSurvey} from '@renofi/analytics';

import {MODAL_TYPES} from './useModals';

export default function useToggleSurvey(modal) {
  useEffect(() => {
    if (modal === MODAL_TYPES.NONE) {
      toggleSurvey('show');
    } else {
      toggleSurvey('hide');
    }
  }, [modal]);
}
