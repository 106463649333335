import {breakpoint} from '@renofi/utils';
import {isTestSession} from '@renofi/analytics';
import {initLogRocket} from '@renofi/analytics/src/logrocket2';
import {initSegment} from '@renofi/analytics/src/segment';
import {initSentry} from '@renofi/analytics/src/sentry';
import {initAccessibe} from '@renofi/analytics/src/accessibe';
import {initDelighted} from '@renofi/analytics/src/delighted';

const isMobile = window.matchMedia(`(max-width: ${breakpoint - 1}px)`).matches;

export const initAnalytics = () => {
  if (isTestSession()) {
    return;
  }
  initSegment();
  initAccessibe();

  if (!isMobile) initDelighted();
};

export const initLogAnalytics = () => {
  initLogRocket({childDomains: ['*']});
  initSentry();
};
